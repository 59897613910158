import { gsap } from 'gsap';

const ANNOUNCEMENT_BANNER = document.querySelector('#topbar');


if (ANNOUNCEMENT_BANNER) {

    ANNOUNCEMENT_BANNER.querySelector('button').addEventListener('click', ()=>{
        gsap.set(ANNOUNCEMENT_BANNER, {display: 'none'});
    })
}